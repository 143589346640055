.nav {
  height: 2.5rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: .5rem;
  display: flex;
}

.logo {
  height: 100%;
}